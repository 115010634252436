import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { ExportResponse } from '@aston/foundation';

@Injectable({
	providedIn: 'root'
})
export class HomeExportsService {

	constructor(
		private http: HttpClient,
		private store: Store){
	}

	exportAgeingBalanceKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/AgeingBalance/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportDetailedAgeingBalanceKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/AgeingBalanceDetailed/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportEstimatedCashingKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/EstimatedCashing/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportOutstandingAmountByDunningLevelsKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/OutstandingDunningLevel/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportAverageOverdueKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/AverageOverdue/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportRealCashingKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/AverageCompletion/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportTurnoverChartDataKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/TotalRevenue/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportTopDebtorsWithClaimsKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/ClientTop/Litigation/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportDsoMonitoringKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/Dso/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	exportTurnoverWorldMapKpis(): Observable<ExportResponse> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get(`${config.apiUrl}/api/Charts/WorldMap/Export`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}
}
