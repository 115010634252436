import { createSelector, MemoizedSelector, createFeatureSelector } from '@ngrx/store';
import { mapToFullNameString } from '@aston/foundation';

import { createEntitySliceSelectors, createListSliceSelectors } from '../functions';
import { selectState as selectAppState } from '../app-store/selectors';
import { IAppState } from '../app-store/state';

import { IHomeActionsState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, IHomeActionsState> = createFeatureSelector<IHomeActionsState>(FeatureName);

const getIsLoading = (state: IHomeActionsState): boolean => state.isLoading;
export const selectIsLoading = createSelector(selectState, getIsLoading);
const getError = (state: IHomeActionsState): string => state.error;
export const selectError = createSelector(selectState, getError);

const getShouldBeReloaded = (state: IHomeActionsState): boolean => !!state.shouldBeReloaded;
export const selectShouldBeReloaded = createSelector(selectState,	getShouldBeReloaded);

export const selectIsEmpty = createSelector(selectState, state => state.isEmpty);

export const selectNoDataToShow = createSelector(selectState, state => {
	return state.outstandingAmountByDunningLevels.entity?.every(c => c.outstandingAmountDunningLevels.every(l => l.totalOutstandingAmount === 0))
		&& state.ageingBalance.entity?.every(c => c.totalOutstandingAmount === 0)
		;
});

export const {
	selectEntity: selectOutstandingAmountByDunningLevels,
	selectError: selectOutstandingAmountByDunningLevelsError,
	selectIsLoading: selectOutstandingAmountByDunningLevelsIsLoading,
	selectIsExporting: selectOutstandingAmountByDunningLevelsIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.outstandingAmountByDunningLevels));

export const {
	selectEntity: selectSuperDebtorScorings,
	selectError: selectSuperDebtorScoringsError,
	selectIsLoading: selectSuperDebtorScoringsIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.superDebtorScorings));

export const {
	selectEntity: selectEstimatedCashingKpis,
	selectError: selectEstimatedCashingKpisError,
	selectIsLoading: selectEstimatedCashingKpisIsLoading,
	selectIsExporting: selectEstimatedCashingKpisIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.estimatedCashing));

export const {
	selectEntity: selectAverageOverdueChartData,
	selectError: selectAverageOverdueChartDataError,
	selectIsLoading: selectAverageOverdueChartDataIsLoading,
	selectIsExporting: selectAverageOverdueChartDataIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.averageOverdueData));

export const {
	selectEntity: selectRealCashingChartData,
	selectError: selectRealCashingChartDataError,
	selectIsLoading: selectRealCashingChartDataIsLoading,
	selectIsExporting: selectRealCashingChartDataIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.realCashingData));

export const {
	selectEntity: selectTurnoverData,
	selectError: selectTurnoverDataError,
	selectIsLoading: selectTurnoverDataIsLoading,
	selectIsExporting: selectTurnoverDataIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.turnoverData));

export const {
	selectList: selectTopOutstandingList,
	selectError: selectTopOutstandingListError,
	selectIsLoading: selectTopOutstandingListIsLoading,
} = createListSliceSelectors(createSelector(selectState, state => state.topOutstandingList));

export const {
	selectEntity: selectTopDebtorsWithClaims,
	selectError: selectTopDebtorsWithClaimsError,
	selectIsLoading: selectTopDebtorsWithClaimsIsLoading,
	selectIsExporting: selectTopDebtorsWithClaimsIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.topDebtorsWithClaims));

export const {
	selectEntity: selectAgeingBalance,
	selectError: selectAgeingBalanceError,
	selectIsLoading: selectAgeingBalanceIsLoading,
	selectIsExporting: selectAgeingBalanceIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.ageingBalance));


export const {
	selectEntity: selectDsoMonitoring,
	selectError: selectDsoMonitoringError,
	selectIsLoading: selectDsoMonitoringIsLoading,
	selectIsExporting: selectDsoMonitoringIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.dsoMonitoring));

export const {
	selectEntity: selectTurnoverWorldMapData,
	selectError: selectTurnoverWorldMapDataError,
	selectIsLoading: selectTurnoverWorldMapDataIsLoading,
	selectIsExporting: selectTurnoverWorldMapDataIsExporting,
} = createEntitySliceSelectors(createSelector(selectState, state => state.turnoverWorldMapData));

const getCurrentUserOption = (state: IAppState) => state.user
	? ({ id: state.user.id, fullName: mapToFullNameString(state.user.fullName) })
	: null;
export const selectCurrentUserOption = createSelector(selectAppState, state => getCurrentUserOption(state));
