import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortFilters, IFilterOperator, IFilterSpec, IFilterFieldSpec, stringOperatorsSpecs, numericOperatorsSpecs, numeric, alphanumExtendedWithSpace, decimal, IFilter, CountryCodeType } from '@aston/foundation';
import { MiddleFilterSpecService } from 'apps/federation/src/app/shared-module/services';
import { TranslateService } from '@ngx-translate/core';
import { AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class SuperCustomersListFilterSpecService {

	constructor(
		private store: Store,
		private shared: MiddleFilterSpecService,
		private translateService: TranslateService
	) {}

	spec$: Observable<IFilterSpec> = this.store.select(AppStoreSelectors.selectHasCreditLimit).pipe(
		map(hasCreditLimit => {
			return {
				items: [
					this.functionalIdSpec(),
					this.superDebtorNameFilterSpec,
					this.legalIdentiferSpec,
					this.superDebtorCountryFilterSpec,
					this.dsoFilterSpec,
					this.scoringFilterSpec,
					this.totalOutstandingAmountSpec,
					this.expiredOutstandingAmountSpec,
					this.averageDelayAmountFilterSpec,
					this.averageDelayDaysFilterSpec,
					this.hasNoCompletedLtgFilterSpec,
					this.hasNoCompletedCtxFilterSpec,
					this.sectorFilterSpec,
					hasCreditLimit ? this.creditLimitFilterSpec : null
				]
				.filter(Boolean)
				.sort(sortFilters(this.translateService))
			};
		})
	);

	legalIdentiferSpec: IFilterFieldSpec = {
		field: {label: 'DataList.Columns.Siret', value: 'legalIdentifier'},
		operatorSpecs: stringOperatorsSpecs,
		validation: numeric
	}

	hasNoCompletedLtgFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.HasNoCompletedLtg', value: 'hasNoCompletedLtg'},
		operatorSpecs: this.shared.booleanOperatorSpecs,
		defaultValue: ['true'],
	}

	hasNoCompletedCtxFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.HasNoCompletedCtx', value: 'hasNoCompletedCtx'},
		operatorSpecs: this.shared.booleanOperatorSpecs,
		defaultValue: ['true'],
	}

	totalOutstandingAmountSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.AmountTotal', value: 'TotalOutstanding'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	dsoFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.DSO', value: 'Dso'},
		operatorSpecs: numericOperatorsSpecs,
		validation: numeric
	}

	scoringFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.Scoring', value: 'ScoringFinal'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	expiredOutstandingAmountSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.AmountExpired', value: 'ExpiredOutstanding'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	averageDelayAmountFilterSpec: IFilterFieldSpec = {
		field: {label: 'SuperDebtors.KPI.AverageOverdueAmount', value: 'averageOverdueAmount'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	averageDelayDaysFilterSpec: IFilterFieldSpec = {
		field: {label: 'SuperDebtors.KPI.AverageOverdueDays', value: 'averageOverdueDays'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	superDebtorNameFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.SuperDebtor', value: 'name'},
		operatorSpecs: this.shared.amongStringSpecs,
		autocompleter: this.shared.completeSuperCustomers,
	}

	superDebtorCountryFilterSpec: IFilterFieldSpec = this.shared.createCountryCodeFilterSpec();

	superDebtorNameOrFunctionalIdFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.SuperDebtorNameOrFunctionalId', value: 'nameOrFunctionalId', backend: '(name|functionalId)'},
		operatorSpecs: stringOperatorsSpecs,
		autocompleter: null
	}

	idsFilterSpec: IFilterFieldSpec = {
		field: {label: 'AccountingDocuments.Fields.ReferencePiece', value: 'id'},
		operatorSpecs: numericOperatorsSpecs,
		validation: numeric
	}

	creditLimitFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.CustomerCreditLimit', value: 'creditLimit'},
		operatorSpecs: numericOperatorsSpecs,
		validation: decimal
	}

	sectorFilterSpec: IFilterFieldSpec = {
		field: {label: 'Forms.CommonLabels.Sector.Label', value: 'sector'},
		operatorSpecs: this.shared.amongSpecs,
		defaultOperator: IFilterOperator.Among,
		autocompleter: this.shared.completeSectorTypes,
		chipsOptions: {
			minimumTextLength: 0,
			showDropdownIfEmpty: true,
		}
	}

	functionalIdSpec = (): IFilterFieldSpec => {
		return {
			field: {label: 'Forms.CommonLabels.Debtors.FunctionalIdSuperDebtor', value: 'functionalId'},
			operatorSpecs: stringOperatorsSpecs,
			validation: alphanumExtendedWithSpace
		}
	}

	named(term: string, operator: IFilterOperator = IFilterOperator.Contains): IFilter {
		return {
			field: this.superDebtorNameOrFunctionalIdFilterSpec.field.value,
			spec: this.superDebtorNameOrFunctionalIdFilterSpec,
			isReadOnly: true,
			values: [term],
			operator,
		}
	}

	byCountries(operator: IFilterOperator, values: CountryCodeType[]): IFilter {
		return {
			field: this.superDebtorCountryFilterSpec.field.value,
			spec: this.superDebtorCountryFilterSpec,
			values,
			operator,
		}
	}

	byRisks(operator: IFilterOperator, values: number[]): IFilter {
		return {
			field: this.scoringFilterSpec.field.value,
			spec: this.scoringFilterSpec,
			values,
			operator,
		}
	}
}
