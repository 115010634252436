
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ICountryMarker } from '@aston/charts/models';
import { AppStoreSelectors } from 'apps/federation/src/app/root-store/app-store';
import { ISuperCustomerEstimatedCashingChartData, SuperCustomerEstimatedCashingMode } from 'apps/federation/src/app/customers-module/models';

import { mapToHomeTurnoverChartData, mapToICountryMarkers, mapToScoringSlices, mapToTopDebtorsWithClaims } from '../functions';
import { IHomeEstimatedCashingKpisApi, IHomeScoringSlicesApi, ITopDebtorsWithClaimsApi, IAverageOverduesDataApi, IHomeWorldMapKpisApi, ITotalRevenueKpisApi, IRealCashingsDataApi } from '../models/api';
import { DsoModel, IHomeAgeingBalanceChartData, IHomeAverageOverdueChartData, IHomeOutstandingByDunningLevelChartData, IHomeRealCashingChartData, IHomeScoringSlices, IHomeTurnoverChartData, ITopDebtorsWithClaims, TopOutstandingItem, TopOutstandingList } from '../models';
import { OutstandingMode } from '../../shared-module/enums';

@Injectable({
	providedIn: 'root'
})
export class HomeService {

	constructor(
		private http: HttpClient,
		private store: Store){
	}

	getOutstandingAmountByDunningLevelsKpis(): Observable<IHomeOutstandingByDunningLevelChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IHomeOutstandingByDunningLevelChartData>(`${config.apiUrl}/api/Charts/OutstandingDunningLevel`)),
		);
	}

	getHomeTopOutstandingClientsKpis(mode: OutstandingMode = OutstandingMode.Total): Observable<TopOutstandingList> {
		const modeBool = mode === OutstandingMode.Total ? 'false' : 'true';
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<TopOutstandingItem[]>(`${config.apiUrl}/api/Charts/ClientTop/Outstanding?isForExpired=${modeBool}`)),
			map(items => ({ items }))
		);
	}

	getAgeingBalanceKpis(): Observable<IHomeAgeingBalanceChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IHomeAgeingBalanceChartData>(`${config.apiUrl}/api/Charts/AgeingBalance`)),
		);
	}

	getEstimatedCashingKpis(mode: SuperCustomerEstimatedCashingMode): Observable<ISuperCustomerEstimatedCashingChartData> {
		const modeBool = (mode === SuperCustomerEstimatedCashingMode.Delayed).toString();
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IHomeEstimatedCashingKpisApi>(`${config.apiUrl}/api/Charts/EstimatedCashing?isDelayed=${modeBool}`)),
		);
	}

	getDsoMonitoringKpis(): Observable<DsoModel[]> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<DsoModel[]>(`${config.apiUrl}/api/Charts/Dso`)),
		);
	}

	getSuperCustomerScoringsKpis(): Observable<IHomeScoringSlices> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IHomeScoringSlicesApi>(`${config.apiUrl}/api/Charts/ScoringRisk`)),
			map(result => mapToScoringSlices(result))
		);
	}

	getTurnoverChartDataKpis(): Observable<IHomeTurnoverChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ITotalRevenueKpisApi>(`${config.apiUrl}/api/Charts/TotalRevenue`)),
			map(result => mapToHomeTurnoverChartData(result))
		);
	}

	getTopDebtorsWithClaimsKpis(): Observable<ITopDebtorsWithClaims> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<ITopDebtorsWithClaimsApi>(`${config.apiUrl}/api/Charts/ClientTop/Litigation`)),
			map(result => mapToTopDebtorsWithClaims(result))
		);
	}

	getAverageOverdueKpis(): Observable<IHomeAverageOverdueChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IAverageOverduesDataApi>(`${config.apiUrl}/api/Charts/AverageOverdue`)),
		);
	}

	getRealCashingKpis(): Observable<IHomeRealCashingChartData> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IRealCashingsDataApi>(`${config.apiUrl}/api/Charts/AverageCompletion`)),
		);
	}

	getTurnoverWorldMapKpis(): Observable<ICountryMarker[]> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<IHomeWorldMapKpisApi[]>(`${config.apiUrl}/api/Charts/WorldMap`)),
			map(data => mapToICountryMarkers(data))
		)
	}
}
